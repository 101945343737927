import { render, staticRenderFns } from "./ModalSeminar.vue?vue&type=template&id=04a66466&scoped=true&lang=pug&"
import script from "./ModalSeminar.vue?vue&type=script&lang=ts&"
export * from "./ModalSeminar.vue?vue&type=script&lang=ts&"
import style0 from "./ModalSeminar.vue?vue&type=style&index=0&id=04a66466&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a66466",
  null
  
)

export default component.exports