











import { Component, Vue, Watch, Ref, Mixins } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import TabBase from '@/components/atoms/TabBase.vue'
import SeminarInformation from '@/components/organisms/SeminarInformation.vue'
import ModalSeminar from '@/components/organisms/ModalSeminar.vue'
import moment from 'moment'
import LocalMoment from '@/components/atoms/LocalMoment.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    TabBase,
    SeminarInformation,
    ModalSeminar,
  },
})
export default class Seminar extends Mixins(LocalMoment) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: '講習会設定', active: true },
  ]

  private seasonsTabDatas = [
    { id: 1, name: '夏期講習' },
    { id: 2, name: '冬期講習' },
    { id: 3, name: '春期講習' },
  ]

  private seasonId = Number(this.$route.query.seasonId) || 1

  private seminarDatas: object[] = []

  @Ref() modalSeminar!: ModalSeminar

  @Watch('seasonId')
  onSeasonIdChanged() {
    this.loadData()
  }

  private showSeminarModal(params: { id: number; title: string }): void {
    this.modalSeminar.showSeminar(params.id, params.title)
  }

  private async loadSeasonSettings() {
    await Vue.prototype.$http.httpWithToken
      .get('/seasonSettings', { params: { branchId: this.branchId, seasonId: this.seasonId } })
      .then((res: any) => {
        this.seminarDatas = res.data.map(
          (seasonSettingsGrade: {
            gradeId: number
            gradeName: string
            gradeCode: string
            from: Date
            to: Date
            seasonSettings: []
          }) => {
            const items = seasonSettingsGrade.seasonSettings.map(
              (seasonSetting: { id: number; subjectName: string; settingExists: boolean }) => {
                return {
                  subject: seasonSetting.subjectName,
                  detail: this.detailButton(seasonSetting, seasonSettingsGrade.gradeName),
                  action: this.editButtons(seasonSetting, seasonSettingsGrade.from, seasonSettingsGrade.gradeCode),
                }
              }
            )
            return {
              grade: seasonSettingsGrade.gradeName,
              period: this.setRangeDate(seasonSettingsGrade.from, seasonSettingsGrade.to),
              periodDate: this.setRangeDateParam(seasonSettingsGrade.from, seasonSettingsGrade.to),
              items: items,
              gradeId: seasonSettingsGrade.gradeId,
              gradeCode: seasonSettingsGrade.gradeCode,
              branchId: this.branchId,
              seasonId: this.seasonId,
              canEdit: this.canEdit(seasonSettingsGrade.from, true),
            }
          }
        )
      })
  }

  private detailButton(seasonSetting: { id: number; subjectName: string; settingExists: boolean }, gradeName: string) {
    if (!seasonSetting.settingExists) return [{ name: '未設定', disabled: true }]
    return [
      {
        onclick: this.showSeminarModal,
        variable: {
          id: seasonSetting.id,
          title: `${gradeName} ${this.getSeasonName()} ${seasonSetting.subjectName}`,
        },
        name: '確認',
      },
    ]
  }

  private editButtons(seasonSetting: { id: number; settingExists: boolean }, from: Date, gradeCode: string) {
    if (!this.canEdit(from, seasonSetting.settingExists))
      return [
        { name: '編集', buttonDisabled: true },
        { name: 'リセット', buttonDisabled: true },
      ]
    return [
      {
        url: `/teacher/setting/seminar-edit/${seasonSetting.id}`,
        name: '編集',
      },
      {
        onclick: this.reset,
        variable: { id: seasonSetting.id, settingExists: seasonSetting.settingExists, gradeCode: gradeCode },
        name: 'リセット',
      },
    ]
  }

  private setRangeDate(from: Date | null, to: Date | null): string {
    if (!from || !to) return '未設定'
    const start = moment(from).locale('ja')
    const end = moment(to).locale('ja')
    return (
      start.format('LL') +
      '~' +
      (start.format('YYYY') === end.format('YYYY') ? end.format('MM月DD日') : end.format('LL'))
    )
  }

  private setRangeDateParam(from: Date | null, to: Date | null): object {
    if (!from || !to) return { start: null, end: null }
    return { start: moment(from).locale('ja').toDate(), end: moment(to).locale('ja').toDate() }
  }

  private canEdit(from: Date | null, settingExists: boolean): boolean {
    if (!from) return true
    return this.afterThanToday(from) || (this.equalToday(from) && !settingExists)
  }

  private getSeasonName(): string {
    const season = this.seasonsTabDatas.find((seasonsTabData) => {
      return seasonsTabData.id === this.seasonId
    })
    if (!season) return ''
    return season.name
  }

  private async destroySeasonSettingsSetting(params: { id: number; settingExists: boolean }) {
    if (!params.settingExists) {
      alert('講習会設定項目が未設定です。')
      return
    }
    if (!confirm('リセットしますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/seasonSettings/${params.id}/setting`)
      .then(() => {
        this.loadSeasonSettings()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        } else {
          alert('エラーが発生しました。ページを更新してお確かめください。')
        }
      })
  }

  private async reset(params: { id: number; settingExists: boolean; gradeCode: string }) {
    Vue.prototype.$loading.start()
    this.$router.replace({ hash: params.gradeCode, query: { seasonId: this.$route.query.seasonId } })
    await this.destroySeasonSettingsSetting(params)
    Vue.prototype.$loading.complete()
  }

  private async loadData() {
    Vue.prototype.$loading.start()
    await this.loadSeasonSettings()
    Vue.prototype.$loading.complete()
  }

  private async mounted() {
    this.loadData()
  }
}
