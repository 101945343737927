






import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalSeminar extends Mixins(ModalBaseMethod) {
  private title = ''

  private seminarRanges: string[] = []

  public async showSeminar(id: number, title: string) {
    this.title = title
    Vue.prototype.$loading.start()
    await this.getSeasonSettingsSettings(id)
    Vue.prototype.$loading.complete()
  }

  private async getSeasonSettingsSettings(id: number) {
    await Vue.prototype.$http.httpWithToken.get(`/seasonSettings/${id}/setting`).then((res: any) => {
      if (res.data.settings.length === 0) {
        alert('データが見つかりません。ページを更新してお確かめください。')
        return
      }
      this.seminarRanges = res.data.settings.map((setting: { grade: string; sUnitName: string }) => {
        return `${setting.grade} ${setting.sUnitName}`
      })
      this.show()
    })
  }
}
