


























import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    TableBase,
    DatePickerIcon,
  },
})
export default class SeminarInformation extends Vue {
  private tableBasePerPage: number | null = null

  private tableBaseFields: object[] = [
    { key: 'subject', label: '教科' },
    { key: 'detail', label: '項目確認' },
    { key: 'action', label: '項目編集' },
  ]

  private tableBaseButtons: string[] = ['detail', 'action']

  @Prop()
  seminarDatas!: []

  @Prop()
  onChange!: Function

  private updated() {
    this.scrollToHash()
  }

  private scrollToHash() {
    const hash = this.$route.hash
    if (hash) {
      const refName = hash.replace('#', '')
      const el = this.$refs[refName] as HTMLElement
      const rectTop = el[0].getBoundingClientRect().top
      window.scrollTo({ top: rectTop + window.pageYOffset - 100, behavior: 'smooth' })
    }
  }

  private setFuncParams(seminarData: { branchId: number; gradeId: number; seasonId: number; gradeCode: string }) {
    return {
      branchId: seminarData.branchId,
      gradeId: seminarData.gradeId,
      seasonId: seminarData.seasonId,
      gradeCode: seminarData.gradeCode,
    }
  }

  private onChangeRangeDate(params: {
    branchId: number
    gradeId: number
    seasonId: number
    gradeCode: string
    start: moment.Moment
    end: moment.Moment
  }) {
    if (params.start > params.end) {
      alert('選択期間が不正です')
      return
    }
    this.$router.replace({ hash: params.gradeCode, query: { seasonId: this.$route.query.seasonId } })
    this.updateRangeDate(params)
  }

  private async updateRangeDate(params: {
    branchId: number
    gradeId: number
    seasonId: number
    start: moment.Moment
    end: moment.Moment
  }) {
    Vue.prototype.$loading.start()
    await this.updateSeasonSettings({
      branchId: params.branchId,
      gradeId: params.gradeId,
      seasonId: params.seasonId,
      from: params.start.format('YYYY-MM-DD'),
      to: params.end.format('YYYY-MM-DD'),
    })
    Vue.prototype.$loading.complete()
  }

  // 講習会期間を更新する
  private async updateSeasonSettings(updateParams: {
    branchId: number
    gradeId: number
    seasonId: number
    from: string
    to: string
  }) {
    await Vue.prototype.$http.httpWithToken
      .patch('/seasonSettings', updateParams)
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert(
            '講習会期間が不正です。開始日には当日以降を設定してください。他講習会期間と重なる日付は設定出来ません。開始日と終了日に同じ日付は設定できません。'
          )
        } else {
          alert('エラーが発生しました。ページを更新してください。')
        }
      })
      .finally(() => {
        this.onChange()
      })
  }
}
